import Events from '../../views/events/Events';
import Gallery from '../../views/gallery/Gallery';
import Menus from '../../views/menus/Menus';
import Posts from '../../views/posts/Posts';
import Reservations from '../../views/reservations/Reservations';

interface INavigation {
  path: string;
  name: string;
  component: typeof Reservations | typeof Events | typeof Posts | typeof Menus | typeof Gallery;
}

interface IRevoGroupNavigation {
  salt: INavigation[];
  lift: INavigation[];
}

const Navigation: IRevoGroupNavigation = {
  salt: [
    {
      path: '/dashboard/events',
      name: 'Evente',
      component: Events,
    },
    {
      path: '/dashboard/posts',
      name: 'Postime',
      component: Posts,
    },
    {
      path: '/dashboard/menus',
      name: 'Menu',
      component: Menus,
    },
    {
      path: '/dashboard/gallery',
      name: 'Galeria',
      component: Gallery,
    },
  ],
  lift: [
    {
      path: '/dashboard/gallery',
      name: 'Galeria',
      component: Gallery,
    },
    {
      path: '/dashboard/menus',
      name: 'Menu',
      component: Menus,
    },
  ],
};

export default Navigation;
