/* eslint-disable */

import { useContext, useState, useEffect } from 'react';
import PrintIcon from '@material-ui/icons/Print';
import AddIcon from '@material-ui/icons/Add';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import agent from '../../api/agent';
import MenuModal from '../../components/Menus/upsert-menu/index';
import { errorHandler } from '../../helpers/utils';
import { AppContext } from '../../contexts/AppContext';
import styles from './Menus.module.scss';
import ListMenus from '../../components/Menus/list-menus';

const Menus = () => {
  const [showDialog, toggleShowDialog] = useState<boolean>(false);
  const [menus, setMenus] = useState<any[]>([]);
  const [selectedMenu, setSelectedMenu] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const { theme, setIsAuthenticated } = useContext(AppContext);

  const toggleLoading = () => {
    setLoading((prevState) => !prevState);
  };

  useEffect(() => {
    const getMenus = async () => {
      try {
        toggleLoading();
        const result = await agent.Menu.list(theme.tenant);
        setMenus(result);
      } catch (error) {
        errorHandler(error, setIsAuthenticated);
      } finally {
        toggleLoading();
      }
    };

    getMenus();
  }, [setIsAuthenticated, theme.tenant]);

  const toggleDialog = () => toggleShowDialog(!showDialog);

  const addMenu = (data: any) => {
    setMenus((prevState) => [...prevState, data]);
  };

  const deleteMenu = (id: string) => {
    setMenus(menus.filter((menu) => menu.id !== id));
  };

  const updateMenu = (data: any) => {
    setMenus(menus.map((menu) => (menu.id === data.id ? { ...data } : menu)));
  };

  return (
    <div className={styles.sectionToPrint}>
      <Box alignItems="center" className={styles.titleSection}>
        <div>
          <Typography variant="h4" gutterBottom className={styles.title}>
            Menaxhimi i Menuve
          </Typography>
          <Typography variant="h4" gutterBottom className={styles.titlePrint}>
            Menu QR Codes
          </Typography>
        </div>
        <div>
          <PrintIcon
            className={styles.printIcon}
            style={{ fontSize: 30, paddingRight: 20 }}
            onClick={() => window.print()}
          />
          <AddIcon
            style={{ fontSize: 30 }}
            className={styles.addIcon}
            onClick={() => {
              toggleDialog();
              setSelectedMenu(null);
            }}
          />
        </div>
      </Box>
      <MenuModal
        open={showDialog}
        menu={selectedMenu}
        toggleDialog={toggleDialog}
        addMenu={addMenu}
        updateMenu={updateMenu}
      />
      <ListMenus
        menus={menus}
        selectMenu={(menu) => setSelectedMenu(menu)}
        deleteMenu={deleteMenu}
        toggleLoading={toggleLoading}
        toggleDialog={toggleDialog}
        loading={loading}
      />
    </div>
  );
};

export default Menus;
