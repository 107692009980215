import Typography from '@material-ui/core/Typography';
import { useState, useContext, useEffect } from 'react';
import AddIcon from '@material-ui/icons/Add';
import Box from '@material-ui/core/Box';
import PostModal from '../../components/Posts/upsert-post';
import ListPosts from '../../components/Posts/list-posts';
import { AppContext } from '../../contexts/AppContext';
import { errorHandler } from '../../helpers/utils';
import agent from '../../api/agent';

const Posts = () => {
  const [showDialog, toggleShowDialog] = useState<boolean>(false);
  const [posts, setPosts] = useState<any[]>([]);
  const [selectedPost, setSelectedPost] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const { theme, setIsAuthenticated } = useContext(AppContext);

  const toggleLoading = () => {
    setLoading((prevState) => !prevState);
  };

  useEffect(() => {
    const getPosts = async () => {
      try {
        toggleLoading();

        const result = await agent.Posts.list(theme.tenant);

        setPosts(result);
      } catch (error) {
        errorHandler(error, setIsAuthenticated);
      } finally {
        toggleLoading();
      }
    };

    getPosts();
  }, [setIsAuthenticated, theme.tenant]);

  const toggleDialog = () => toggleShowDialog(!showDialog);

  const addPost = (data: any) => {
    setPosts((prevState) => [...prevState, data]);
  };

  const deletePost = (id: string) => {
    setPosts(posts.filter((post) => post.id !== id));
  };

  const updatePost = (data: any) => {
    setPosts(posts.map((post) => (post.id === data.id ? { ...data } : post)));
  };

  return (
    <>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography variant="h4" gutterBottom>
          Postime
        </Typography>
        <AddIcon
          style={{ fontSize: 30 }}
          onClick={() => {
            toggleDialog();
            setSelectedPost(null);
          }}
        />
      </Box>
      <PostModal
        open={showDialog}
        post={selectedPost}
        toggleDialog={toggleDialog}
        addPost={addPost}
        updatePost={updatePost}
      />
      <ListPosts
        posts={posts}
        selectPost={(post) => setSelectedPost(post)}
        deletePost={deletePost}
        toggleLoading={toggleLoading}
        toggleDialog={toggleDialog}
        loading={loading}
      />
    </>
  );
};
export default Posts;
