import { ITheme } from '../api/models/design';
import liftLogo from '../assets/images/lift_logo.png';
import saltLogo from '../assets/images/salt_logo.png';
import { Tenant } from './constants';

const LiftTheme: ITheme = {
  tenant: Tenant.Lift,
  logo: liftLogo,
  colours: {
    primaryBackgroundColor: '#2f3330',
    primaryFontColor: '#b29782',
  },
};

const SaltTheme: ITheme = {
  tenant: Tenant.Salt,
  logo: saltLogo,
  colours: {
    primaryBackgroundColor: '#30475e',
    primaryFontColor: '#ba8a6e',
  },
};

const themes = {
  lift: LiftTheme,
  salt: SaltTheme,
};

export default themes;
