export enum Status {
  Request = 'request',
  Approved = 'approved',
  Cancelled = 'cancelled',
}

export enum Tenant {
  Lift = 'lift',
  Salt = 'salt',
}

export enum ReservationType {
  Table = 'table',
  Event = 'event',
}

export const SALT_ADMIN = 'admin.salt.al';
export const LIFT_ADMIN = 'admin.liftrestaurant.al';

// These are the types that we store on the database for each image.
// These are used by both web clients to render images in different sections
// of the web page.

export const galleryTypes = {
  salt: {
    HOME_GALLERY1: 'homeGallery1',
    HOME_GALLERY2: 'homeGallery2',
    HOME_GALLERY3: 'homeGallery3',
    ABOUT_IMAGE: 'aboutImage',
    MENU_GALLERY: 'menuGallery',
    MENU_BACKGROUND: 'menuBackground',
    BOOKING_BACKGROUND: 'bookingBackground',
    GALLERY: 'gallery',
    CONTACT_BACKGROUND: 'contactBackground',
  },
  lift: {
    HOME_IMAGE: 'homeImage',
    EVENT_IMAGE: 'eventImage',
    MENU_IMAGE: 'menuImage',
    RESERVATION_IMAGE: 'reservationImage',
    DELIVERY_IMAGE: 'deliveryImage',
    GALLERY: 'gallery',
  }
}

// These are section types used only by the admin client.
// These are not stored anywhere, they are used just for organizing
// images on the admin panel gallery menu.
// All images with "multi === true" will be grouped by their "type" property.
// All the remaining images with "multi === false" will be shown on the "Background" section
// on the admin panel.

export const sectionTypes = {
  MENU_GALLERY: 'menuGallery',
  GALLERY: 'gallery',
  BACKGROUND: 'background',
}