import { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Switch, Route, Redirect, useRouteMatch } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import NavBar from '../../components/NavBar/NavBar';
import Navigation from '../../api/models/navigation';
import { AppContext } from '../../contexts/AppContext';

const useStyles = makeStyles({
  container: {
    // height: 'calc(100vh - 80px)',
    padding: '50px 5%',
  },
});

const Home = () => {
  const classes = useStyles();
  const match = useRouteMatch();
  const { theme } = useContext(AppContext);

  return (
    <>
      <NavBar />
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        className={classes.container}
      >
        <Switch>
          <Route exact path={`${match.url}`}>
            <Redirect to={`${match.url}/bookings`} />
          </Route>
          {Navigation[theme.tenant].map((item, index) => (
            <Route key={index.toString()} exact path={item.path} component={item.component} />
          ))}
        </Switch>
      </Box>
    </>
  );
};

export default Home;
