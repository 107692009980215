import React, { useContext } from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Divider from '@material-ui/core/Divider';
import { AppContext } from '../../../contexts/AppContext';
import { errorHandler } from '../../../helpers/utils';
import agent from '../../../api/agent';
import LoadingIndicator from '../../LoadingIndicator/LoadingIndicator';

interface IProps {
  events: any[];
  deleteEvent: (id: string) => void;
  toggleLoading: () => void;
  loading: boolean;
  toggleDialog: () => void;
  selectEvent: (event: any) => void;
}

const ListEvents: React.FC<IProps> = ({
  events,
  selectEvent,
  deleteEvent,
  toggleLoading,
  toggleDialog,
  loading,
}) => {
  const { setIsAuthenticated } = useContext(AppContext);

  const onDeleteHandler = async (id: string) => {
    try {
      toggleLoading();
      await agent.Events.delete(id);

      deleteEvent(id);
      toggleLoading();
    } catch (error) {
      errorHandler(error, setIsAuthenticated);
    }
  };

  if (loading) return <LoadingIndicator />;

  return (
    <List>
      {events.map((event, index) => (
        <div key={index.toString()}>
          <ListItem style={{ padding: '15px 0' }}>
            <img
              src={event.photo}
              alt="placeholder"
              style={{ width: 72, height: 72, paddingRight: 15 }}
            />
            <ListItemText primary={event.name} secondary={event.description} />
            <IconButton
              edge="end"
              aria-label="edit"
              onClick={() => {
                selectEvent(event);
                toggleDialog();
              }}
            >
              <EditIcon />
            </IconButton>
            <IconButton edge="end" aria-label="delete" onClick={() => onDeleteHandler(event.id)}>
              <DeleteIcon />
            </IconButton>
          </ListItem>
          <Divider />
        </div>
      ))}
    </List>
  );
};

export default ListEvents;
