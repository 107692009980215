/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useContext, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import DialogTitle from '@material-ui/core/DialogTitle';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, DateTimePicker } from '@material-ui/pickers';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { makeStyles } from '@material-ui/core/styles';
import agent from '../../../api/agent';
import { AppContext } from '../../../contexts/AppContext';
import { errorHandler } from '../../../helpers/utils';
import LoadingIndicator from '../../LoadingIndicator/LoadingIndicator';

interface IProps {
  open: boolean;
  toggleDialog: () => void;
  addEvent: (data: any) => void;
  updateEvent: (data: any) => void;
  event: any;
}

const useStyles = makeStyles({
  imageUploadError: {
    margin: '5px 0',
    color: '#f44336',
    fontSize: '0.75rem',
  },
});

const UpsertEvent: React.FC<IProps> = ({ open, event, toggleDialog, addEvent, updateEvent }) => {
  const { setIsAuthenticated, theme } = useContext(AppContext);
  const classes = useStyles();
  const [loading, setLoading] = useState<boolean>(false);
  const [initialValues, setInitialValues] = useState<any>({});

  const upsertEvent = async (data: any, resetForm: Function) => {
    try {
      setLoading(true);

      if (event) {
        const result = await agent.Events.update(event?.id, { ...data, tenant: theme.tenant });

        updateEvent(result);
      } else {
        const result = await agent.Events.add({ ...data, tenant: theme.tenant });

        addEvent(result);
      }

      resetForm({});
      toggleDialog();
    } catch (error) {
      errorHandler(error, setIsAuthenticated);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setInitialValues({
      name: event?.name ?? '',
      start: event?.start ?? new Date(),
      end: event?.end ?? new Date(),
      description: event?.description ?? '',
      file: null,
    });
  }, [event]);

  const formik = useFormik({
    initialValues,
    onSubmit: (values: any, actions) => {
      upsertEvent(values, actions.resetForm);
    },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      name: Yup.string().required('Vendosni titullin'),
      start: Yup.date(),
      end: Yup.date().min(
        Yup.ref('start'),
        () => 'Data e mbarimit duhet të jetë pas asaj të fillimit'
      ),
      file: Yup.mixed()
        .required('Zgjidhni një imazh')
        .test(
          'fileSize',
          'Imazhi nuk mund të jetë mbi 5MB',
          (value) => value && value.size <= 5000000
        ),
    }),
  });

  const {
    values,
    touched,
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    resetForm,
  } = formik;

  return (
    <>
      <Dialog
        open={open}
        onClose={() => {
          resetForm({});
          toggleDialog();
        }}
        fullWidth
        maxWidth="sm"
        aria-labelledby="form-add-event"
      >
        <form onSubmit={handleSubmit}>
          <DialogTitle id="form-add-event">{event ? 'Ndrysho event' : 'Shto event'}</DialogTitle>
          <DialogContent>
            <TextField
              margin="normal"
              id="name"
              label="Titulli"
              value={values.name}
              type="text"
              fullWidth
              helperText={errors.name && touched.name && errors.name}
              error={!!(errors.name && touched.name)}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Grid
                container
                spacing={2}
                justify="space-between"
                style={{ padding: '30px 0 15px 0' }}
              >
                <Grid item xs={6}>
                  <DateTimePicker
                    value={values.start}
                    disablePast
                    onChange={(e) => {
                      setFieldValue('start', e);
                    }}
                    id="start"
                    label="Data e fillimit"
                    format="dd/MM/yyyy HH:mm"
                    name="start"
                    ampm={false}
                    fullWidth
                    helperText={errors.start && touched.start}
                    error={!!(errors.start && touched.start)}
                    onBlur={handleBlur}
                  />
                </Grid>
                <Grid item xs={6}>
                  <DateTimePicker
                    value={values.end}
                    disablePast
                    onChange={(e) => setFieldValue('end', e)}
                    id="end"
                    label="Data e mbarimit"
                    format="dd/MM/yyyy HH:mm"
                    name="end"
                    ampm={false}
                    fullWidth
                    helperText={errors?.end}
                    error={!!errors.end}
                    onBlur={handleBlur}
                  />
                </Grid>
              </Grid>
            </MuiPickersUtilsProvider>
            <TextField
              margin="dense"
              id="description"
              name="description"
              label="Përmbajtja"
              type="text"
              fullWidth
              value={values.description}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <div style={{ paddingTop: 30 }}>
              <input
                accept="image/*"
                id="file"
                name="file"
                type="file"
                onChange={(e) => e.target.files && setFieldValue('file', e.target.files[0])}
                style={{ display: 'none' }}
              />
              <label htmlFor="file">
                <Button variant="contained" color="primary" component="span">
                  Shto një imazh
                </Button>
              </label>
              <label style={{ fontSize: 12, paddingLeft: 15 }}>{values?.file?.name ?? ''}</label>
              {errors?.file && touched?.file && (
                <p className={classes.imageUploadError}>{errors?.file}</p>
              )}
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              onClick={() => {
                resetForm({});
                toggleDialog();
              }}
              disabled={!!loading}
            >
              Anullo
            </Button>
            <Button color="primary" variant="contained" type="submit" disabled={!!loading}>
              {loading ? <LoadingIndicator size={20} inPlace /> : 'Ruaj'}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export default UpsertEvent;
