import { Dispatch, SetStateAction } from 'react';
import { toast } from 'react-toastify';

export const getStoredToken = () => window.localStorage.getItem('token');
export const setStoredToken = (token: string) => localStorage.setItem('token', token);

export const logout = (setIsAuthenticated: Dispatch<SetStateAction<boolean>>) => {
  localStorage.removeItem('token');
  setIsAuthenticated(false);
};

export const errorHandler = (error: any, setIsAuthenticated: Dispatch<SetStateAction<boolean>>) => {
  const message = error?.errorMessage;
  const statusCode = error?.statusCode;

  if (statusCode === 401) {
    logout(setIsAuthenticated);
    // message = 'Session has expired or you are not authorized. Please try again';
  }

  toast.error(message ?? 'Server error');
};
