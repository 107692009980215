import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { AppContext } from '../../contexts/AppContext';

interface IProps {
  size?: number;
  inPlace?: boolean;
}

const LoadingIndicator: React.FC<IProps> = ({ size = 40, inPlace = false }) => {
  const { theme } = useContext(AppContext);

  const useStyles = makeStyles({
    container: {
      position: 'absolute',
      top: '50%',
      left: '50%',
    },
    spinner: {
      color: theme.colours.primaryBackgroundColor,
    },
  });

  const classes = useStyles();

  return (
    <div className={inPlace ? '' : classes.container}>
      <CircularProgress size={size} className={classes.spinner} />
    </div>
  );
};

export default LoadingIndicator;
