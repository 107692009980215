/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext } from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Divider from '@material-ui/core/Divider';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import { AppContext } from '../../../contexts/AppContext';
import { errorHandler } from '../../../helpers/utils';
import agent from '../../../api/agent';
import LoadingIndicator from '../../LoadingIndicator/LoadingIndicator';

interface IProps {
  posts: any[];
  deletePost: (id: string) => void;
  toggleLoading: () => void;
  loading: boolean;
  toggleDialog: () => void;
  selectPost: (post: any) => void;
}

const ListPosts: React.FC<IProps> = ({
  posts,
  selectPost,
  deletePost,
  toggleLoading,
  toggleDialog,
  loading,
}) => {
  const { setIsAuthenticated } = useContext(AppContext);

  const onDeleteHandler = async (id: string) => {
    try {
      toggleLoading();
      await agent.Posts.delete(id);

      deletePost(id);
      toggleLoading();
    } catch (error) {
      errorHandler(error, setIsAuthenticated);
    }
  };

  if (loading) return <LoadingIndicator />;

  const renderLink = (link: string) => (
    <Typography>
      <Link href={link} target="_blank">
        Link
      </Link>
    </Typography>
  );

  return (
    <List>
      {posts.map((post, index) => (
        <div key={index.toString()}>
          <ListItem style={{ padding: '15px 0' }}>
            <img
              src={post.photo}
              alt="placeholder"
              style={{ width: 72, height: 72, paddingRight: 15 }}
            />
            <ListItemText primary={post.title} secondary={renderLink(post.url)} />
            <IconButton
              edge="end"
              aria-label="edit"
              onClick={() => {
                selectPost(post);
                toggleDialog();
              }}
            >
              <EditIcon />
            </IconButton>
            <IconButton edge="end" aria-label="delete" onClick={() => onDeleteHandler(post.id)}>
              <DeleteIcon />
            </IconButton>
          </ListItem>
          <Divider />
        </div>
      ))}
    </List>
  );
};

export default ListPosts;
