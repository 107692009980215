import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { getStoredToken } from '../helpers/utils';
import { ILoginRequest, ILoginResponse } from './models/auth';

const { REACT_APP_URL } = process.env;

axios.defaults.baseURL = REACT_APP_URL;

axios.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    const configuration = config;

    const token = getStoredToken();
    if (token) configuration.headers.Authorization = `Bearer ${token}`;

    return configuration;
  },
  (error: AxiosError) => Promise.reject(error)
);

axios.interceptors.response.use(
  (response: AxiosResponse) => response,
  (error: AxiosError) => {
    // const status = error.response?.status;

    throw error.response?.data;
  }
);

const responseData = (response: AxiosResponse) => response?.data;

const request = {
  get: (url: string, params: any = undefined) => axios.get(url, { params }).then(responseData),
  post: (url: string, body: {}) => axios.post(url, body).then(responseData),
  put: (url: string, body: {}) => axios.put(url, body).then(responseData),
  patch: (url: string, body: {}) => axios.patch(url, body).then(responseData),
  delete: (url: string) => axios.delete(url).then(responseData),
  postFormData: async (url: string, data: any) => {
    const formData = new FormData();

    Object.keys(data).forEach((property) => {
      formData.append(property.toString(), data[property]);
    });

    const response = await axios.post(url, formData, {
      headers: { 'content-type': 'multipart/form-data' },
    });

    return responseData(response);
  },
  patchFormData: async (url: string, data: any) => {
    const formData = new FormData();

    Object.keys(data).forEach((property) => {
      formData.append(property.toString(), data[property]);
    });

    const response = await axios.patch(url, formData, {
      headers: { 'content-type': 'multipart/form-data' },
    });

    return responseData(response);
  },
};

const Reservations = {
  list: (tenant: string): Promise<any> => request.get(`/reservations/list/${tenant}`),
  update: (id: string, body: any) => request.patch(`/reservations/${id}`, body),
};

const Events = {
  list: (tenant: string): Promise<any> => request.get(`/events/list/${tenant}`),
  add: (event: any): Promise<any> => request.postFormData('/events/create', event),
  update: (id: string, body: any) => request.patchFormData(`/events/${id}`, body),
  delete: (id: string): Promise<any> => request.delete(`/events/${id}`),
};

const Posts = {
  list: (tenant: string): Promise<any> => request.get(`/posts/list/${tenant}`),
  add: (post: any): Promise<any> => request.postFormData('/posts/create', post),
  update: (id: string, body: any) => request.patchFormData(`/posts/${id}`, body),
  delete: (id: string): Promise<any> => request.delete(`/posts/${id}`),
};

const Gallery = {
  list: (tenant: string): Promise<any> => request.get(`/gallery/list/${tenant}`),
  add: (post: any): Promise<any> => request.postFormData('/gallery/create', post),
  update: (id: string, body: any) => request.patchFormData(`/gallery/${id}`, body),
  delete: (id: string): Promise<any> => request.delete(`/gallery/${id}`),
};

const Menu = {
  list: (tenant: string): Promise<any> => request.get(`/menu/list/${tenant}`),
  add: (post: any): Promise<any> => request.postFormData('/menu/create', post),
  update: (id: string, body: any) => request.patchFormData(`/menu/${id}`, body),
  delete: (id: string): Promise<any> => request.delete(`/menu/${id}`),
};

const Auth = {
  login: (body: ILoginRequest): Promise<ILoginResponse> => request.post('/auth/login', body),
};

export default {
  Reservations,
  Events,
  Posts,
  Gallery,
  Menu,
  Auth,
};
