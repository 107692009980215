/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useContext, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { makeStyles } from '@material-ui/core/styles';
import agent from '../../../api/agent';
import { AppContext } from '../../../contexts/AppContext';
import { errorHandler } from '../../../helpers/utils';
import LoadingIndicator from '../../LoadingIndicator/LoadingIndicator';
import CropImage from '../crop-image/index';
import { galleryTypes } from '../../../helpers/constants';
import { Image } from '../../../api/models/gallery-sections';

interface IProps {
  open: boolean;
  toggleDialog: () => void;
  addImage: (data: any) => void;
  updateImage: (data: any) => void;
  image: Image;
  type: string;
  multi: boolean,
}

const useStyles = makeStyles({
  imageUploadError: {
    margin: '5px 0',
    color: '#f44336',
    fontSize: '0.75rem',
  },
});

const ratio = {
  salt: {
    [galleryTypes.salt.HOME_GALLERY1]: 3 / 2,
    [galleryTypes.salt.HOME_GALLERY2]: 3 / 2,
    [galleryTypes.salt.HOME_GALLERY3]: 3 / 2,
    [galleryTypes.salt.ABOUT_IMAGE]: 552 / 692,
    [galleryTypes.salt.MENU_GALLERY]: 2 / 3,
    [galleryTypes.salt.MENU_BACKGROUND]: 3 / 2,
    [galleryTypes.salt.BOOKING_BACKGROUND]: 1954 / 2927,
    [galleryTypes.salt.CONTACT_BACKGROUND]: 1958 / 1307,
  },
  lift: {
    [galleryTypes.lift.HOME_IMAGE]: 91 / 121,
    [galleryTypes.lift.EVENT_IMAGE]: 203 / 292,
    [galleryTypes.lift.MENU_IMAGE]: 580 / 387,
    [galleryTypes.lift.RESERVATION_IMAGE]: 482 / 641,
    [galleryTypes.lift.DELIVERY_IMAGE]: 252 / 300,
  }
}

type upsertImageDataType = {
  description: string,
  multi: boolean,
  photo: File,
  type: string,
}

const UpsertImage: React.FC<IProps> = ({ open, image, type, multi, toggleDialog, addImage, updateImage }) => {
  const { setIsAuthenticated, theme } = useContext(AppContext);
  const classes = useStyles();
  const [showDialog, toggleShowDialog] = useState<Boolean>(false)
  const [loading, setLoading] = useState<boolean>(false);
  const [initialValues, setInitialValues] = useState<any>({});
  const [file, setFile] = useState<any>({file: null, fileName: null });
  const [croppedImage, setCroppedImage] = useState<any>(null);  

  const weAreUpdating = Boolean(image);
  
  // On the admin panel, all images with the property "multi === true" 
  // will be shown as different sections. For these images, we can use the
  // aspect ratio defined on the section level.
  // For all other images (website background images with type "multi === false"), 
  // we can't use the section ratio, because images within this section can have
  // different ratios. These images can only be updated (they are existing records on the database), 
  // so we can get the type from the existing image.

  let aspect;
  if (!multi && weAreUpdating) {
    aspect = ratio[theme.tenant][image.type]
  } else {
    aspect = ratio[theme.tenant][type]
  }

  const imageOnChangeHandler = (e: any, setFieldValue: any) => {
    if (e.target.files) {
      const reader = new FileReader();
      setFieldValue('photo', e.target.files[0]);
      reader.addEventListener("load", () => {
        setFile({file: reader.result, fileName: e.target.files[0].name})
      }
      );
      reader.readAsDataURL(e.target.files[0]);
      toggleShowDialog(true)
    }
  };

  const upsertImage = async (data: upsertImageDataType, resetForm: Function) => {
    try {
      setLoading(true);

      const requestBody = {
        ...data,
        tenant: theme.tenant,
      }

      // This is done on purpose.
      // Gallery images will not have a predefined ratio.
      // For gallery images we will use the file input value directly.
      // For all other images, we will use the cropped image value.
      if (type !== galleryTypes[theme.tenant].GALLERY) {
        requestBody.photo = croppedImage;
      }

      if (weAreUpdating) {
        const result = await agent.Gallery.update(image?.id, requestBody);
        updateImage(result);
      } else {
        const result = await agent.Gallery.add(requestBody);
        addImage(result);
      }

      resetForm({});
      toggleDialog();
    } catch (error) {
      errorHandler(error, setIsAuthenticated);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setInitialValues({
      description: image?.description ?? '',
      photo: null,
      type,
      multi,
    });
  }, [image, type, multi]);

  let validationSchema;
  if (weAreUpdating) {
    validationSchema = Yup.object().shape({
      description: Yup.string().required('Vendosni përshkrimin'),
    })
  } else (
    validationSchema = Yup.object().shape({
      description: Yup.string().required('Vendosni përshkrimin'),
      photo: Yup.mixed()
        .required('Zgjidhni një imazh')
        .test(
          'fileSize',
          'Imazhi nuk mund të jetë mbi 5MB',
          (value) => value && value.size <= 5000000
        ),
    })
  )

  const formik = useFormik({
    initialValues,
    onSubmit: (values: any, actions) => {
      upsertImage(values, actions.resetForm);
    },
    enableReinitialize: true,
    validationSchema,
  });

  const {
    values,
    touched,
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    resetForm,
  } = formik;

  return (
    <>
      <Dialog
        open={open}
        onClose={() => {
          resetForm({});
          toggleDialog();
        }}
        fullWidth
        maxWidth="sm"
        aria-labelledby="form-add-event"
      >
        <form onSubmit={handleSubmit}>
          <DialogTitle id="form-add-event">{image ? 'Ndrysho imazhin' : 'Shto imazh'}</DialogTitle>
          <DialogContent>
            <TextField
              margin="normal"
              id="description"
              name="description"
              label="Përshkrimi"
              value={values.description}
              type="text"
              fullWidth
              helperText={errors.description && touched.description && errors.description}
              error={!!(errors.description && touched.description)}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <div style={{ paddingTop: 30 }}>
              <input
                accept="image/*"
                id="photo"
                name="photo"
                type="file"
                onChange={(e) => imageOnChangeHandler(e, setFieldValue)}
                style={{ display: 'none' }}
              />
              <label htmlFor="photo">
                <Button variant="contained" color="primary" component="span">
                  Shto një imazh
                </Button>
              </label>
              <label style={{ fontSize: 12, paddingLeft: 15 }}>{values?.photo?.name ?? ''}</label>
              {errors?.photo && touched?.photo && (
                <p className={classes.imageUploadError}>{errors?.photo}</p>
              )}
            </div>
            {type !== galleryTypes[theme.tenant].GALLERY && (
              <CropImage
                open={showDialog}
                toggleShowDialog={toggleShowDialog}
                file={file.file}
                fileName={file.fileName}
                aspect={aspect}
                zoomInit={1}
                cropInit={{ x: 0, y: 0 }}
                setCroppedImage={setCroppedImage}
              />
            )}
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              onClick={() => {
                resetForm({});
                toggleDialog();
              }}
              disabled={!!loading}
            >
              Anullo
            </Button>
            <Button color="primary" variant="contained" type="submit" disabled={!!loading}>
              {loading ? <LoadingIndicator size={20} inPlace /> : 'Ruaj'}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export default UpsertImage;
