import React, { Dispatch, SetStateAction, useState } from 'react';
import { ITheme } from '../api/models/design';
import themes from '../helpers/theme';

interface IContextProps {
  theme: ITheme;
  setTheme: Dispatch<SetStateAction<ITheme>>;
  isAuthenticated: boolean;
  setIsAuthenticated: Dispatch<SetStateAction<boolean>>;
  authenticationError: string | null;
  setAuthenticationError: Dispatch<SetStateAction<string | null>>;
}

export const AppContext = React.createContext({} as IContextProps);

export const AppProvider = ({ children }: any) => {
  const [theme, setTheme] = useState<ITheme>(themes.salt);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [authenticationError, setAuthenticationError] = useState<string | null>(null);

  return (
    <AppContext.Provider
      value={{
        theme,
        setTheme,
        isAuthenticated,
        setIsAuthenticated,
        authenticationError,
        setAuthenticationError,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
