import { QRCode } from 'react-qr-svg';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Card from '@material-ui/core/Card';
import React, { useContext } from 'react';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import CardActions from '@material-ui/core/CardActions';
import { makeStyles } from '@material-ui/core/styles';
import agent from '../../../api/agent';
import { AppContext } from '../../../contexts/AppContext';
import { errorHandler } from '../../../helpers/utils';
import LoadingIndicator from '../../LoadingIndicator/LoadingIndicator';
import styles from './index.module.scss';

interface IProps {
  menus: any[];
  deleteMenu: (id: string) => void;
  toggleLoading: () => void;
  loading: boolean;
  toggleDialog: () => void;
  selectMenu: (menu: any) => void;
}

const useStyles = makeStyles({
  content: {
    padding: 0,
  },

  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: 0,
  },
});

const ListMenus: React.FC<IProps> = ({
  menus,
  selectMenu,
  deleteMenu,
  toggleLoading,
  toggleDialog,
  loading,
}) => {
  const { theme, setIsAuthenticated } = useContext(AppContext);
  const classes = useStyles();
  const onDeleteHandler = async (id: string) => {
    try {
      toggleLoading();
      await agent.Menu.delete(id);

      deleteMenu(id);
      toggleLoading();
    } catch (error) {
      errorHandler(error, setIsAuthenticated);
    }
  };

  if (loading) return <LoadingIndicator />;

  return (
    <div className={styles.cardContainer}>
      {menus.map((menu, index) => (
        <Card className={styles.card} key={index.toString()}>
          <CardMedia
            style={{ display: 'flex', justifyContent: 'space-around' }}
            className={styles.cardMedia}
          >
            <QRCode
              value={menu.urlSq}
              level="L"
              fgColor={theme.colours.primaryFontColor}
              className={styles.qrCode}
            />
            <QRCode
              value={menu.urlEn}
              level="L"
              fgColor={theme.colours.primaryFontColor}
              className={styles.qrCode}
            />
          </CardMedia>
          <CardContent className={classes.content}>
            <div className={styles.titles}>
              <Typography gutterBottom variant="h6">
                {menu.titleSq}
              </Typography>
              <Typography gutterBottom variant="h6">
                {menu.titleEn}
              </Typography>
            </div>
          </CardContent>
          <div className={styles.cardActions}>
            <CardActions className={classes.actions}>
              <IconButton
                edge="end"
                aria-label="edit"
                onClick={() => {
                  selectMenu(menu);
                  toggleDialog();
                }}
              >
                <EditIcon className={styles.editIcon} />
              </IconButton>
              <IconButton edge="end" aria-label="delete" onClick={() => onDeleteHandler(menu.id)}>
                <DeleteIcon className={styles.deleteIcon} />
              </IconButton>
            </CardActions>
          </div>
        </Card>
      ))}
    </div>
  );
};

export default ListMenus;
