import { useState, useEffect, useContext } from 'react';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import Box from '@material-ui/core/Box';
import agent from '../../api/agent';
import ImageModal from '../../components/Gallery/upsert-image';
import { AppContext } from '../../contexts/AppContext';
import { errorHandler } from '../../helpers/utils';
import ListImages from '../../components/Gallery/list-images';
import GalleryNavigation from '../../api/models/gallery-sections';
import { sectionTypes } from '../../helpers/constants';

const Gallery = () => {
  const [showDialog, toggleShowDialog] = useState<Record<string, boolean>>({
    [sectionTypes.GALLERY]: false,
    [sectionTypes.BACKGROUND]: false,
    [sectionTypes.MENU_GALLERY]: false,
  });
  const [images, setImages] = useState<any[]>([]);
  const [selectedImage, setSelectedImage] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const { theme, setIsAuthenticated } = useContext(AppContext);

  const toggleLoading = () => {
    setLoading((prevState) => !prevState);
  };

  useEffect(() => {
    const getImages = async () => {
      try {
        toggleLoading();

        const result = await agent.Gallery.list(theme.tenant);

        setImages(result);
      } catch (error) {
        errorHandler(error, setIsAuthenticated);
      } finally {
        toggleLoading();
      }
    };
    getImages();
  }, [setIsAuthenticated, theme.tenant]);

  const toggleDialog = (type: string) => toggleShowDialog((prevState) => ({
    ...prevState,
    [type]: !prevState[type],
  }));

  const addImage = (data: any) => {
    setImages((prevState) => [...prevState, data]);
  };

  const deleteImage = (id: string) => {
    setImages(images.filter((image) => image.id !== id));
  };

  const updateImage = (data: any) => {
    setImages(images.map((image) => (image.id === data.id ? { ...data } : image)));
  };

  return (
    <>
      {GalleryNavigation[theme.tenant].map((item, index) => (
        <div key={index.toString()} style={{ marginTop: 25 }}>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Typography variant="h4" gutterBottom>
              {item.name}
            </Typography>
            {item.multi && (
              <AddIcon
                style={{ fontSize: 30, cursor: 'pointer' }}
                onClick={() => {
                  toggleDialog(item.section);
                  setSelectedImage(null);
                }}
              />
            )}
          </Box>
          <ImageModal
            open={showDialog[item.section]}
            image={selectedImage}
            type={item.section}
            multi={item.multi}
            toggleDialog={() => toggleDialog(item.section)}
            addImage={addImage}
            updateImage={updateImage}
          />
          <ListImages
            images={images}
            type={item.section}
            multi={item.multi}
            selectImage={(image) => setSelectedImage(image)}
            deleteImage={deleteImage}
            toggleLoading={toggleLoading}
            toggleDialog={() => toggleDialog(item.section)}
            loading={loading}
          />
        </div>
      ))}
    </>
  );
};
export default Gallery;
