import { sectionTypes } from '../../helpers/constants';

interface ISection {
  name: string;
  section: string;
  multi: boolean
}

interface IRevoGallerySection {
  salt: ISection[];
  lift: ISection[];
}

export type Image = {
  id: string,
  description: string,
  tenant: string,
  type: string,
  multi: string,
  photo: string,
  createdAt: string,
  updatedAt: string,
}

const GallerySection: IRevoGallerySection = {
  salt: [
    {
      name: 'Galeria',
      section: sectionTypes.GALLERY,
      multi: true,
    },
    {
      name: 'Galeria e menusë',
      section: sectionTypes.MENU_GALLERY,
      multi: true,
    },
    {
      name: 'Background',
      section: sectionTypes.BACKGROUND,
      multi: false,
    },
  ],
  lift: [
    {
      name: 'Galeria',
      section: sectionTypes.GALLERY,
      multi: true,
    },
    {
      name: 'Background',
      section: sectionTypes.BACKGROUND,
      multi: false,
    },
  ],
};

export default GallerySection;
