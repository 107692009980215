/* eslint-disable jsx-a11y/anchor-is-valid */
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import { makeStyles } from '@material-ui/core/styles';
import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { AppContext } from '../../contexts/AppContext';
import { logout } from '../../helpers/utils';
import Navigation from '../../api/models/navigation';

const NavBar = () => {
  const { theme, setIsAuthenticated } = useContext(AppContext);

  const useStyles = makeStyles({
    header: {
      backgroundColor: theme.colours.primaryBackgroundColor,
      height: 80,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    title: {
      padding: '0 15px',
      color: theme.colours.primaryFontColor,
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'none',
      },
    },
    logo: {
      width: 50,
    },
    logout: {
      color: theme.colours.primaryFontColor,
    },
  });

  const classes = useStyles();

  return (
    <AppBar position="static">
      <Toolbar className={classes.header}>
        <div>
          <IconButton edge="start" color="inherit" aria-label="menu">
            <img className={classes.logo} src={theme.logo} alt="Logo" />
          </IconButton>
          {Navigation[theme.tenant].map((item, index) => (
            <Link key={index.toString()} to={item.path} className={classes.title}>
              {item.name}
            </Link>
          ))}

          {/* <Link to="/dashboard/events" className={classes.title}>
            Evente
          </Link>
          <Link to="/dashboard/posts" className={classes.title}>
            Postime
          </Link>
          <Link to="/dashboard/menus" className={classes.title}>
            Menu
          </Link>
          <Link to="/dashboard/gallery" className={classes.title}>
            Galeria
          </Link> */}
        </div>
        <div>
          <IconButton aria-label="logout" onClick={() => logout(setIsAuthenticated)}>
            <PowerSettingsNewIcon className={classes.logout} />
          </IconButton>
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default NavBar;
