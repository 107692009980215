import Typography from '@material-ui/core/Typography';
import { useState, useEffect, useContext } from 'react';
import AddIcon from '@material-ui/icons/Add';
import Box from '@material-ui/core/Box';
import EventModal from '../../components/Events/upsert-event/index';
import ListEvents from '../../components/Events/list-events/index';
import { AppContext } from '../../contexts/AppContext';
import agent from '../../api/agent';
import { errorHandler } from '../../helpers/utils';
import LoadingIndicator from '../../components/LoadingIndicator/LoadingIndicator';

const Events = () => {
  const [showDialog, toggleShowDialog] = useState<boolean>(false);

  const [events, setEvents] = useState<any[]>([]);
  const [selectedEvent, setSelectedEvent] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const { theme, setIsAuthenticated } = useContext(AppContext);

  const addEvent = (data: any) => {
    setEvents((prevState) => [...prevState, data]);
  };

  const deleteEvent = (id: string) => {
    setEvents(events.filter((event) => event.id !== id));
  };

  const updateEvent = (data: any) => {
    setEvents(events.map((event) => (event.id === data.id ? { ...data } : event)));
  };

  const toggleLoading = () => {
    setLoading((prevState) => !prevState);
  };

  useEffect(() => {
    const getEvents = async () => {
      try {
        toggleLoading();

        const result = await agent.Events.list(theme.tenant);

        setEvents(result);
      } catch (error) {
        errorHandler(error, setIsAuthenticated);
      } finally {
        toggleLoading();
      }
    };

    getEvents();
  }, [setIsAuthenticated, theme.tenant]);

  const toggleDialog = () => toggleShowDialog(!showDialog);

  if (loading) return <LoadingIndicator />;

  return (
    <>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography variant="h4" gutterBottom>
          Evente
        </Typography>
        <AddIcon
          style={{ fontSize: 30 }}
          onClick={() => {
            toggleDialog();
            setSelectedEvent(null);
          }}
        />
      </Box>
      <EventModal
        open={showDialog}
        event={selectedEvent}
        toggleDialog={toggleDialog}
        addEvent={addEvent}
        updateEvent={updateEvent}
      />
      <ListEvents
        events={events}
        selectEvent={(event) => setSelectedEvent(event)}
        deleteEvent={deleteEvent}
        toggleLoading={toggleLoading}
        toggleDialog={toggleDialog}
        loading={loading}
      />
    </>
  );
};
export default Events;
