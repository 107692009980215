/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useContext, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { AppContext } from '../../../contexts/AppContext';
import LoadingIndicator from '../../LoadingIndicator/LoadingIndicator';
import agent from '../../../api/agent';
import { errorHandler } from '../../../helpers/utils';

interface IProps {
  open: boolean;
  toggleDialog: () => void;
  addMenu: (data: any) => void;
  updateMenu: (data: any) => void;
  menu: any;
}

const useStyles = makeStyles({
  imageUploadError: {
    margin: '5px 0',
    color: '#f44336',
    fontSize: '0.75rem',
  },
});

const UpsertMenus: React.FC<IProps> = ({ open, toggleDialog, menu, addMenu, updateMenu }) => {
  const { setIsAuthenticated, theme } = useContext(AppContext);
  const classes = useStyles();
  const [loading, setLoading] = useState<boolean>(false);
  const [initialValues, setInitialValues] = useState<any>({});

  const upsertMenu = async (data: any, resetForm: Function) => {
    try {
      setLoading(true);

      if (menu) {
        const result = await agent.Menu.update(menu?.id, {
          ...data,
          tenant: theme.tenant,
        });

        updateMenu(result);
      } else {
        const result = await agent.Menu.add({ ...data, tenant: theme.tenant });
        addMenu(result);
      }

      resetForm({});
      toggleDialog();
    } catch (e) {
      errorHandler(e, setIsAuthenticated);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setInitialValues({
      titleSq: menu?.titleSq ?? '',
      urlSq: null,
      titleEn: menu?.titleEn ?? '',
      urlEn: null,
    });
  }, [menu]);

  const validationSchemaForUpdate = Yup.object().shape({
    titleSq: Yup.string(),
    urlSq: Yup
      .mixed()
      .nullable(true)
      .test(
        'fileSize',
        'Menu nuk mund të jetë mbi 2MB. Shkoni te link për ta kompresuar PDF: https://www.ilovepdf.com/compress_pdf',
        (value) => !value ? true : value.size <= 2000000
      ),
    titleEn: Yup.string(),
    urlEn: Yup
      .mixed()
      .nullable(true)
      .test(
        'fileSize',
        'Menu nuk mund të jetë mbi 2MB. Shkoni te link për ta kompresuar PDF: https://www.ilovepdf.com/compress_pdf',
        (value) => !value ? true : value.size <= 2000000
      ),
  });

  const validationSchema = Yup.object().shape({
    titleSq: Yup.string().required('Vendosni titullin e menusë shqip'),
    urlSq: Yup.mixed().required('Zgjidhni një menu shqip').test(
      'fileSize',
      'Menu nuk mund të jetë mbi 2MB. Shkoni te link për ta kompresuar PDF: https://www.ilovepdf.com/compress_pdf',
      (value) => value && value.size <= 2000000
    ),
    titleEn: Yup.string().required('Vendosni titullin e menusë anglisht'),
    urlEn: Yup.mixed().required('Zgjidhni një menu anglisht').test(
      'fileSize',
      'Menu nuk mund të jetë mbi 2MB. Shkoni te link për ta kompresuar PDF: https://www.ilovepdf.com/compress_pdf',
      (value) => value && value.size <= 2000000
    ),
  });

  const formik = useFormik({
    initialValues,
    onSubmit: (values: any, actions) => {
      upsertMenu(values, actions.resetForm);
    },
    enableReinitialize: true,
    validationSchema: menu ? validationSchemaForUpdate : validationSchema,
  });

  const {
    values,
    touched,
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    resetForm,
  } = formik;

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="sm"
      aria-labelledby="form-add-menu"
      onClose={() => {
        resetForm({});
        toggleDialog();
      }}
    >
      <form onSubmit={handleSubmit}>
        <DialogTitle id="form-add-event">{menu ? 'Ndrysho menu' : 'Shto menu'}</DialogTitle>
        <DialogContent>
          <TextField
            margin="normal"
            id="titleSq"
            name="titleSq"
            label="Titllu i menusë shqip"
            value={values.titleSq}
            type="text"
            fullWidth
            helperText={errors.titleSq && touched.titleSq && errors.titleSq}
            error={!!(errors.titleSq && touched.titleSq)}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <div style={{ paddingTop: 30 }}>
            <input
              accept="application/pdf"
              id="urlSq"
              name="urlSq"
              type="file"
              onChange={(e) => e.target.files && setFieldValue('urlSq', e.target.files[0])}
              style={{ display: 'none' }}
            />
            <label htmlFor="urlSq">
              <Button variant="contained" color="primary" component="span">
                Shto menu shqip
              </Button>
            </label>
            <label style={{ fontSize: 12, paddingLeft: 15 }}>{values?.urlSq?.name ?? ''}</label>
            {errors?.urlSq && touched?.urlSq && (
              <p className={classes.imageUploadError}>{errors?.urlSq}</p>
            )}
          </div>
          <TextField
            margin="normal"
            id="titleEn"
            name="titleEn"
            label="Titllu i menusë anglisht"
            value={values.titleEn}
            type="text"
            fullWidth
            helperText={errors.titleEn && touched.titleEn && errors.titleEn}
            error={!!(errors.titleEn && touched.titleEn)}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <div style={{ paddingTop: 30 }}>
            <input
              accept="application/pdf"
              id="urlEn"
              name="urlEn"
              type="file"
              onChange={(e) => e.target.files && setFieldValue('urlEn', e.target.files[0])}
              style={{ display: 'none' }}
            />
            <label htmlFor="urlEn">
              <Button variant="contained" color="primary" component="span">
                Shto menu anglisht
              </Button>
            </label>
            <label style={{ fontSize: 12, paddingLeft: 15 }}>{values?.urlEn?.name ?? ''}</label>
            {errors?.urlEn && touched?.urlEn && (
              <p className={classes.imageUploadError}>{errors?.urlEn}</p>
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => {
              resetForm({});
              toggleDialog();
            }}
            disabled={!!loading}
          >
            Anullo
          </Button>
          <Button color="primary" variant="contained" type="submit" disabled={!!loading}>
            {loading ? <LoadingIndicator size={20} inPlace /> : 'Ruaj'}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
export default UpsertMenus;
