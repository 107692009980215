/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect, useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Alert from '@material-ui/lab/Alert';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { RouteComponentProps, Redirect, useHistory } from 'react-router-dom';
import agent from '../../api/agent';
import { AppContext } from '../../contexts/AppContext';
import { getStoredToken, setStoredToken } from '../../helpers/utils';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  successMessage: { color: 'green' },
  errorMessage: { color: 'red' },
  alert: {
    marginTop: 10,
    display: 'flex',
    justifyContent: 'center',
  },
}));

interface IProps extends RouteComponentProps {}

const Login: React.FC<IProps> = ({ location }) => {
  const classes = useStyles();
  const [loading, setLoading] = useState<boolean>(false);
  const history = useHistory();

  const lastLocation: any = location.state || { from: { pathname: '/' } };

  const {
    theme,
    isAuthenticated,
    setIsAuthenticated,
    authenticationError,
    setAuthenticationError,
  } = useContext(AppContext);

  useEffect(() => {
    const token = getStoredToken();

    if (token) {
      setIsAuthenticated(true);
    }
  }, [setIsAuthenticated]);

  const login = async (data: any, resetForm: Function) => {
    try {
      setLoading(true);
      const result = await agent.Auth.login({ ...data, tenant: theme.tenant });

      setIsAuthenticated(true);
      setAuthenticationError(null);
      setStoredToken(result.token);
      resetForm({});

      history.push('/dashboard/bookings');
    } catch (error) {
      setIsAuthenticated(false);

      setAuthenticationError(error?.errorMessage ?? 'Server error');
    } finally {
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    onSubmit: (values: any, actions) => {
      login(values, actions.resetForm);
    },
    validationSchema: Yup.object().shape({
      email: Yup.string().email('Formati i email-it është i gabuar').required('Vendosni email-in'),
      password: Yup.string().required('Vendosni fjalëkalimin'),
    }),
  });

  const { values, touched, errors, handleBlur, handleChange, handleSubmit } = formik;

  if (isAuthenticated) {
    return <Redirect to={lastLocation?.from?.pathname} />;
  }

  return (
    <Container maxWidth="xs">
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Identifikohu
        </Typography>

        <form onSubmit={handleSubmit}>
          <TextField
            name="email"
            id="email"
            label="Email"
            value={values.email}
            variant="outlined"
            margin="normal"
            fullWidth
            helperText={errors.email && touched.email && errors.email}
            error={!!(errors.email && touched.email)}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <TextField
            name="password"
            id="password"
            label="Fjalëkalimi"
            value={values.password}
            variant="outlined"
            margin="normal"
            fullWidth
            type="password"
            helperText={errors.password && touched.password && errors.password}
            error={!!(errors.password && touched.password)}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={loading}
          >
            Hyr
          </Button>

          {authenticationError && (
            <Alert severity="error" icon={false} className={classes.alert}>
              {authenticationError}
            </Alert>
          )}
        </form>
      </div>
    </Container>
  );
};

export default Login;
