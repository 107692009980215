import { ToastContainer } from 'react-toastify';
import { AppProvider } from './contexts/AppContext';
import Main from './Main';

const App = () => (
  <>
    <ToastContainer
      position="top-right"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop
      closeOnClick
      rtl={false}
      pauseOnFocusLoss={false}
      draggable={false}
      pauseOnHover={false}
    />
    <AppProvider>
      <Main />
    </AppProvider>
  </>
);

export default App;
