/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useContext, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { makeStyles } from '@material-ui/core/styles';
import agent from '../../../api/agent';
import { AppContext } from '../../../contexts/AppContext';
import { errorHandler } from '../../../helpers/utils';
import LoadingIndicator from '../../LoadingIndicator/LoadingIndicator';

interface IProps {
  open: boolean;
  toggleDialog: () => void;
  addPost: (data: any) => void;
  updatePost: (data: any) => void;
  post: any;
}

const useStyles = makeStyles({
  imageUploadError: {
    margin: '5px 0',
    color: '#f44336',
    fontSize: '0.75rem',
  },
});

const UpsertPost: React.FC<IProps> = ({ open, post, toggleDialog, addPost, updatePost }) => {
  const { setIsAuthenticated, theme } = useContext(AppContext);
  const classes = useStyles();
  const [loading, setLoading] = useState<boolean>(false);
  const [initialValues, setInitialValues] = useState<any>({});

  const upsertPost = async (data: any, resetForm: Function) => {
    try {
      setLoading(true);

      if (post) {
        const result = await agent.Posts.update(post?.id, { ...data, tenant: theme.tenant });

        updatePost(result);
      } else {
        const result = await agent.Posts.add({ ...data, tenant: theme.tenant });

        addPost(result);
      }

      resetForm({});
      toggleDialog();
    } catch (error) {
      errorHandler(error, setIsAuthenticated);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setInitialValues({
      title: post?.title ?? '',
      url: post?.url ?? '',
      content: post?.content ?? '',
      file: null,
    });
  }, [post]);

  const formik = useFormik({
    initialValues,
    onSubmit: (values: any, actions) => {
      upsertPost(values, actions.resetForm);
    },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      title: Yup.string().required('Vendosni titullin'),
      url: Yup.string().required('Vendosni linkun').url('Format i gabuar'),
      file: Yup.mixed()
        .required('Zgjidhni një imazh')
        .test(
          'fileSize',
          'Imazhi nuk mund të jetë mbi 5MB',
          (value) => value && value.size <= 5000000
        ),
    }),
  });

  const {
    values,
    touched,
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    resetForm,
  } = formik;

  return (
    <>
      <Dialog
        open={open}
        onClose={() => {
          resetForm({});
          toggleDialog();
        }}
        fullWidth
        maxWidth="sm"
        aria-labelledby="form-add-event"
      >
        <form onSubmit={handleSubmit}>
          <DialogTitle id="form-add-event">{post ? 'Ndrysho postimin' : 'Shto post'}</DialogTitle>
          <DialogContent>
            <TextField
              margin="normal"
              id="title"
              name="title"
              label="Titulli"
              value={values.title}
              type="text"
              fullWidth
              helperText={errors.title && touched.title && errors.title}
              error={!!(errors.title && touched.title)}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <TextField
              margin="normal"
              id="url"
              name="url"
              label="URL"
              type="text"
              fullWidth
              value={values.url}
              helperText={errors.url && touched.url && errors.url}
              error={!!(errors.url && touched.url)}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <TextField
              margin="normal"
              id="content"
              name="content"
              label="Përmbajtja"
              type="text"
              fullWidth
              value={values.content}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <div style={{ paddingTop: 30 }}>
              <input
                accept="image/*"
                id="file"
                name="file"
                type="file"
                onChange={(e) => e.target.files && setFieldValue('file', e.target.files[0])}
                style={{ display: 'none' }}
              />
              <label htmlFor="file">
                <Button variant="contained" color="primary" component="span">
                  Shto një imazh
                </Button>
              </label>
              <label style={{ fontSize: 12, paddingLeft: 15 }}>{values?.file?.name ?? ''}</label>
              {errors?.file && touched?.file && (
                <p className={classes.imageUploadError}>{errors?.file}</p>
              )}
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              onClick={() => {
                resetForm({});
                toggleDialog();
              }}
              disabled={!!loading}
            >
              Anullo
            </Button>
            <Button color="primary" variant="contained" type="submit" disabled={!!loading}>
              {loading ? <LoadingIndicator size={20} inPlace /> : 'Ruaj'}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export default UpsertPost;
