import React, { useContext, useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { AppContext } from './contexts/AppContext';
import { SALT_ADMIN } from './helpers/constants';
import themes from './helpers/theme';
import PrivateRoute from './routing/PrivateRoute';
import Login from './views/auth/Login';
import Home from './views/home/Home';

const Main = () => {
  const { setTheme } = useContext(AppContext);

  const url = window.location.href;

  useEffect(() => {
    if (url.includes(SALT_ADMIN)) setTheme(themes.salt);
    else setTheme(themes.lift);
  }, [setTheme, url]);

  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <Redirect to="/dashboard" />
        </Route>
        <Route path="/login" component={Login} />
        <PrivateRoute path="/dashboard" component={Home} />
      </Switch>
    </Router>
  );
};

export default Main;
