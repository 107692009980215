import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { useState } from 'react';
import Cropper from 'react-easy-crop';
import getCroppedImg from "./crop-image";

const CropImage = ({
  file,
  fileName,
  aspect,
  zoomInit,
  cropInit,
  setCroppedImage,
  open,
  toggleShowDialog
}: any) => {
  const [zoom, setZoom] = useState(zoomInit);
  const [crop, setCrop] = useState(cropInit);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const onCropChange = (cropItem: any) => {
    setCrop(cropItem);
  };

  const onZoomChange = (zoomItem: any) => {
    setZoom(zoomItem);
  };

  const onCropComplete = (croppedArea: any, croppedAreaPixelsItem: any) => {
    setCroppedAreaPixels(croppedAreaPixelsItem);
  };

  const onCrop = async () => {
    const croppedImageUrl = await getCroppedImg(file, fileName, croppedAreaPixels!);
    setCroppedImage(croppedImageUrl);
    toggleShowDialog(false);
  };

  return (
    <>
      <Dialog
        fullWidth
        maxWidth='md'
        open={open}
      >
        <DialogTitle>Modifikoni imazhin</DialogTitle>
        <DialogContent
          style={{
            width: '100%',
            height: '75vh',
            position: 'relative'
          }}
        >
          <div
            className='backdrop'
            style={{
              maxWidth: '100%',
              maxHeight: '100%',
            }}
          >
            <div
              className="crop-container"
              style={{
                maxWidth: '100%',
                maxHeight: '100%',
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
              }}
            >
              <Cropper
                image={file}
                zoom={zoom}
                crop={crop}
                aspect={aspect}
                onCropChange={onCropChange}
                onZoomChange={onZoomChange}
                onCropComplete={onCropComplete}
              />
            </div>
          </div>
        </DialogContent>
        <DialogActions
          style={{display: 'flex'}}
        >
          <input
            type='range'
            style={{display: 'flex', flex: 1, margin: '1rem 1rem'}}
            min={1}
            max={5}
            step={0.1}
            value={zoom}
            onInput={(e: any) => {
              onZoomChange(e.target.value);
            }}
          />
          <Button color="primary" variant="contained" type='submit' onClick={onCrop}>Crop</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CropImage;