import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { AppContext } from '../contexts/AppContext';

interface IProps {
  component: any;
  path: string;
}

const PrivateRoute: React.FC<IProps> = ({ component: Component, path }) => {
  const { isAuthenticated } = useContext(AppContext);
  const renderComponent = (props: any) => {
    if (!isAuthenticated) {
      return (
        <>
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location },
            }}
          />
        </>
      );
    }

    return <Component />;
  };

  return (
    <>
      <Route path={path} render={(props) => renderComponent(props)} />
    </>
  );
};

export default PrivateRoute;

// const PrivateRoute: React.FC<IProps> = ({ component: Component, ...rest }) => {
//   const { isAuthenticated } = useContext(AppContext);

//   return (
//     <Route
//       {...rest}
//       render={
//         (props) =>
//           isAuthenticated ? (
//             <Component {...props} />
//           ) : (
//             <Redirect
//               to={{
//                 pathname: '/login',
//                 state: { from: props.location },
//               }}
//             />
//           )
//         // eslint-disable-next-line react/jsx-curly-newline
//       }
//     />
//   );
// };

// export default PrivateRoute;
