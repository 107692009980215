import React, { useContext } from 'react';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import IconButton from '@material-ui/core/IconButton';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import LoadingIndicator from '../../LoadingIndicator/LoadingIndicator';
import agent from '../../../api/agent';
import { errorHandler } from '../../../helpers/utils';
import { AppContext } from '../../../contexts/AppContext';

interface IProps {
  images: any[];
  type: string;
  multi: boolean;
  deleteImage: (id: string) => void;
  toggleLoading: () => void;
  loading: boolean;
  toggleDialog: () => void;
  selectImage: (image: any) => void;
}

const ListImages: React.FC<IProps> = ({
  images,
  type,
  multi,
  selectImage,
  deleteImage,
  toggleLoading,
  toggleDialog,
  loading,
}) => {
  const { setIsAuthenticated } = useContext(AppContext);

  const onDeleteHandler = async (id: string) => {
    try {
      toggleLoading();
      await agent.Gallery.delete(id);

      deleteImage(id);
      toggleLoading();
    } catch (error) {
      errorHandler(error, setIsAuthenticated);
    }
  };

  // All images with "multi === true" will be grouped by their "type" property.
  // All the remaining images with "multi === false" will be shown 
  // on the "Background" section.

  let items;
  if (!multi) {
    items = images.filter((image) => image.multi === 'false')
  } else {
    items = images.filter((image) => image.type === type)
  }


  if (loading) return <LoadingIndicator />;

  return (
    <Grid container spacing={2} style={{ paddingTop: 20 }}>
      {items.map((image, index) => (
        <Grid item xs={12} md={4} key={index.toString()}>
          <Card>
            <CardMedia style={{ height: 200, width: '100%' }} image={image.photo} title="Image" />
            <CardContent style={{ paddingBottom: 16 }}>
              <Box display="flex" alignItems="center" justifyContent="space-between">
                <Typography variant="h6" component="h6">
                  {image?.description ?? ''}
                </Typography>
                <div>
                  <IconButton
                    edge="end"
                    aria-label="edit"
                    onClick={() => {
                      selectImage(image);
                      toggleDialog();
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                  {multi && (
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={() => onDeleteHandler(image.id)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  )}
                </div>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

export default ListImages;
